import React, { useState, useEffect } from 'react';
import './People.css';
  import teamData from '../../jsons/teamData';

  const People = () => {
    const [teamMembers, setTeamMembers] = useState({});
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
      setTeamMembers(teamData);
      setAnimate(true);
    }, []);

    const getImagePath = (path) => {
      try {
        return require(`../../assets/${path}`);
      } catch (err) {
        return 'path/to/placeholder/image.jpg';
      }
    };

  return (
    <div className="people-container">
      <section>
        <h1 class="title-animation">Principal Investigators</h1>
        <div className={`team-list ${animate ? 'animate' : ''}`}>
          {teamMembers.PrincipalInvestigators?.map((researcher, index) => (
            <div key={index} className="principal-investigator-card">
              <a href={researcher?.weblink}><img src={getImagePath(researcher.photo)} alt={researcher.name} className="team-photo" /></a>
              <div className="principal-investigator-details team-details">
                <h2>{researcher.name}</h2>
                <p>{researcher?.designation}</p>
                <p>{researcher?.affiliation}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h1 class="title-animation">Researchers and Developers</h1>
        <div className={`team-list ${animate ? 'animate' : ''}`}>
          {teamMembers.ResearchersAndDevelopers?.map((researcher, index) => (
            <div key={index} className="researcher-card">
              <a href={researcher?.weblink}><img src={getImagePath(researcher.photo)} alt={researcher.name} className="team-photo" /></a>
              <div className="researcher-details team-details">
                <h2>{researcher.name}</h2>
                <p>{researcher?.designation}</p>
                <p>{researcher?.affiliation}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default People;
