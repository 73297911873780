import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import trumpImage from '../../assets/trump.jpg'; 
import harrisImage from '../../assets/harris.jpg'
import pollexample from '../../assets/pollexample.png';
import { FaInfoCircle } from 'react-icons/fa';

const Header = () => {
  const [socialPollPercentage_t, setSocialPollPercentage_t] = useState(73);
  const [socialPollPercentage_b, setSocialPollPercentage_b] = useState(31);
  const [traditionalPollPercentage_t, setTraditionalPollPercentage_t] = useState(49);
  const [traditionalPollPercentage_b, setTraditionalPollPercentage_b] = useState(51);
  const [debiasPollPercentage_t, setDebiasPollPercentage_t] = useState(52);
  const [debiasPollPercentage_b, setDebiasPollPercentage_b] = useState(80);

  console.log(debiasPollPercentage_b);
  console.log(debiasPollPercentage_t);

  const updateRandomValues = () => {
    const randomValues = Array.from({ length: 20 }, () => Math.floor(Math.random() * 100));

    let counter = 0;
    const interval = setInterval(() => {
      setSocialPollPercentage_t(randomValues[counter % 20]);
      setTraditionalPollPercentage_t(randomValues[(counter + 1) % 20]);
      setDebiasPollPercentage_t(randomValues[(counter + 2) % 20]);
      setSocialPollPercentage_b(randomValues[counter % 20]);
      setTraditionalPollPercentage_b(randomValues[(counter + 1) % 20]);
      setDebiasPollPercentage_b(randomValues[(counter + 2) % 20]);
      counter++;
      if (counter === 25) {
        clearInterval(interval);
        setSocialPollPercentage_t(70);
        setTraditionalPollPercentage_t(49);
        setDebiasPollPercentage_t(52);
        setSocialPollPercentage_b(30);
        setTraditionalPollPercentage_b(51);
        setDebiasPollPercentage_b(48);
      }
    }, 100); 
  };

  useEffect(() => {
    updateRandomValues();
  }, []);

  return (
    <div className="header__container">
      <div className="header__content">
        <div className="header__intro">
          <h1>We Study Social Polls</h1>
          <p>Social polls are informal polls published on social media platforms.</p>
          <img src={pollexample} alt="Poll Example" className='pollexample'/>
          <p>Polls on X (formerly Twitter) provide valuable insights into public opinion, but their results are often biased. Here, we measure these biases and correct them to estimate support for presidential candidates (see <Link to="/methodology">our methodology</Link>).</p>
        </div>
        <div className="header__polls">
          <div className='description'>
          <h3>Who's Winning?</h3>
          <div className='info-icon-container'>
          <FaInfoCircle className='info-icon' />
          <div className='info-tooltip'>
            Averages over the most recent month before the election day.
          </div>
          </div>
        </div>
          <div className="header__images">
            <img src={trumpImage} alt="Trump" className="redborder"/>
            <img src={harrisImage} alt="Biden" className="blueborder"/>
          </div>
          <ul className="header__polls">
          <li>Social polls: </li>
            <span><span style={{color: '#e04040'}}>{socialPollPercentage_t}%    </span><span style={{ color: '#762a2a' }}>vs</span> <span style={{color: 'blue'}}>{socialPollPercentage_b}%    </span></span>
            <li>De-biased social polls: </li>
            <span><span style={{color: '#e04040'}}>{debiasPollPercentage_t}%</span><span style={{ color: '#762a2a' }}> vs </span> <span style={{color: 'blue'}}>{debiasPollPercentage_b}%</span></span>
            <li>Traditional polls(from <a href="https://projects.fivethirtyeight.com/polls/president-general/2024/national/" target='blank'><span>538</span></a>): </li>
            <span><span style={{color: '#e04040'}}>{traditionalPollPercentage_t}%    </span><span style={{ color: '#762a2a' }}>vs</span> <span style={{color: 'blue'}}>{traditionalPollPercentage_b}%    </span></span>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
