import React from 'react';
import './Methodology.css';

const Methodology = () => {
  return (
    <div className="methodology-container">
      <h1 className="methodology-header">Methodology</h1>
      <div className="methodology-intro">
        <p>
          Here, we explain how the numbers shown on this webpage are computed. We describe how we identify, average, and de-bias relevant social media polls using methods introduced in our research publications <a href="https://journalqd.org/article/view/5897" target="_blank" rel="noopener noreferrer">[1]</a>, <a href="https://arxiv.org/abs/2405.11146" target="_blank" rel="noopener noreferrer">[2]</a>, <a href="https://arxiv.org/html/2406.03340v1" target="_blank" rel="noopener noreferrer">[3]</a>.
        </p>
      </div>
      <div className="methodology-section">
        <h3>Identification of Social Polls Gauging Support for Trump and Biden</h3>
        <p>
          To estimate support for each candidate in the 2024 US presidential election, we use relevant polls from X (formerly Twitter). We identify polls gauging support for Trump and Biden, while filtering out polls on other topics, such as sports, movies, individual partisan issues, even presidential debates.
        </p>
        <p>
          To this end, we select polls that contain the first or the last names of the presidential candidates among poll options. Then, we identify polls asking some version of the question, “Who will you vote for?” or “Who will win the election?,” by using GPT-4o, an advanced LLM by OpenAI.
        </p>
        <p>
          Human annotators first label a set of polls for relevance to ones that gauge support for candidates in the 2024 election, which we then use as few-shot examples to prompt the LLM. We have found that GPT-4o classifies each poll with a precision of 0.93. The polls deemed relevant by GPT-4o are used in the next steps.
        </p>
      </div>
      <div className="methodology-section">
        <h3>Averages of Head-to-Head Outcomes of Election Polls</h3>
        <p>
          Most social polls list only the main two presidential candidates, i.e., Trump and Biden, among poll response options. In such cases, the percentages of votes for the two candidates sum up to 100%. Other polls, however, list other poll candidates among poll options and, because of the votes cast for these other candidates, the percentage of votes for the two main candidates sum up to a value lower than 100%. To account for that, we calculate head-to-head percentages, i.e., the share of the two-party vote each of the main candidates achieves. To this end, we consider only the votes for the main two presidential candidates, while ignoring votes for all other candidates. The percentage shown on the homepage for social polls is the head-to-head percentage averaged over the past month. Our Timeline shows both the head-to-head numbers, as well as the averages of the poll outcomes that include votes for other candidates.
        </p>
      </div>
      <div className="methodology-section">
        <h3>De-Biasing Social Poll Outcomes</h3>
        <p>
          In our research, we find that the users interacting with election polls on X are not representative of the US voting population, which results in biased poll outcomes <a href="https://journalqd.org/article/view/5897" target="_blank" rel="noopener noreferrer">[1]</a>, <a href="https://arxiv.org/abs/2405.11146" target="_blank" rel="noopener noreferrer">[2]</a>. We de-bias these poll outcomes so that they better represent US voters, following the so-called regression and post-stratification methodology <a href="https://arxiv.org/html/2406.03340v1" target="_blank" rel="noopener noreferrer">[3]</a>.
        </p>
        <p>
          First, we analyze the attributes of potential social poll voters. Given that X poll voters are anonymous, we take the retweeters of each poll as a set of potential voters. We identify the age and gender <a href="https://dl.acm.org/doi/10.1145/3308558.3313684#" target="_blank" rel="noopener noreferrer">[4]</a> and partisanship <a href="https://www.cambridge.org/core/journals/political-analysis/article/birds-of-the-same-feather-tweet-together-bayesian-ideal-point-estimation-using-twitter-data/91E37205F69AEA32EF27F12563DC2A0A" target="_blank" rel="noopener noreferrer">[5]</a> of such potential voters in each election poll using machine learning models. Finally, for each social poll we compute the fractions of potential voters in that poll having a certain age, gender, and partisanship. We use only 8 major strata: males, females; individuals between 18 and 29 years old, between 30 and 39 years old, or older than 39; individuals with Republican, Democratic, or moderate partisanship.
        </p>
        <p>
          Second, we regress outcomes of social polls against the fractions of potential voters belonging to each population stratum in the respective polls. The regression coefficients estimate the support among individuals from the respective stratum for each of the two presidential election candidates.
        </p>
        <p>
          Third, we estimate the support for the presidential candidates that is more representative of the US voting population by using the coefficients of the above regression model, while replacing the biased fractions of users belonging to a certain population stratum with population percentages for each strata from the 2020 exit polls, which are more representative of the US voting population. For more details on this regression and poststratification methodology, please refer to our recent paper <a href="https://arxiv.org/html/2406.03340v1" target="_blank" rel="noopener noreferrer">[3]</a>. The resulting estimate of the support for the presidential candidates is shown on our front page.
        </p>
      </div>
      <div className="methodology-section">
        <h3>References</h3>
        <ul>
          <li><a href="https://journalqd.org/article/view/5897" target="_blank" rel="noopener noreferrer">[1] Scarano, S., Vasudevan, V., Samory, M., Yang, J., & Grabowicz, P. A. (2024). Analyzing Support for US Presidential Candidates in Twitter Polls. Analyzing Support for U.S. Presidential Candidates in Social Polls. Journal of Quantitative Description: Digital Media, 4.</a></li>
          <li><a href="https://arxiv.org/abs/2405.11146" target="_blank" rel="noopener noreferrer">[2] Scarano, S., Vasudevan, V., Samory, M., Yang, K. C., Yang, J., & Grabowicz, P. A. (2024). Election Polls on Social Media: Prevalence, Biases, and Voter Fraud Beliefs. arXiv preprint arXiv:2405.11146.</a></li>
          <li><a href="https://arxiv.org/html/2406.03340v1" target="_blank" rel="noopener noreferrer">[3] Scarano, S., Vasudevan, V., Bagchi, C., Samory, M., Yang, J., & Grabowicz, P. A. (2024). Analyzing and Estimating Support for US Presidential Candidates in Twitter Polls. arXiv:2406.03340.</a></li>
          <li><a href="https://dl.acm.org/doi/10.1145/3308558.3313684#" target="_blank" rel="noopener noreferrer">[4] Wang, Z., Hale, S., Adelani, D. I., Grabowicz, P., Hartman, T., Flöck, F., & Jurgens, D. (2019, May). Demographic Inference and Representative Population Estimates from Multilingual Social Media Data. In The World Wide Web Conference (WWW '19). Association for Computing Machinery, New York, NY, USA, 2056–2067.</a></li>
          <li><a href="https://www.cambridge.org/core/journals/political-analysis/article/birds-of-the-same-feather-tweet-together-bayesian-ideal-point-estimation-using-twitter-data/91E37205F69AEA32EF27F12563DC2A0A" target="_blank" rel="noopener noreferrer">[5] Barberá, P. (2015). Birds of the Same Feather Tweet Together: Bayesian Ideal Point Estimation Using Twitter Data. Political Analysis, 23(1), 76-91.</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Methodology;
