import React, { useState, useEffect, useRef } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/lab_image.png'; 
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path) => currentPath === path ? 'active' : '';

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    if (toggleMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleMenu]);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <Link to="/home">
        <h2>Social polls</h2>
        </Link>
        <Link to="/home">
        <p className="by">by</p>
        </Link>
        <Link to="/home">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="Logo" />
        </div>
        </Link>
        
        <div className="gpt3__navbar-links_container">
          <p><Link to="/home" className={isActive('/home')}>Home</Link></p>
          <p><Link to="/timechart" className={isActive('/timechart')}>Timeline</Link></p>
          <p><Link to="/methodology" className={isActive('/methodology')}>Methodology</Link></p>
          <p><Link to="/media" className={isActive('/media')}>Media</Link></p>
          <p><Link to="/people" className={isActive('/people')}>Team</Link></p>
        </div>
      </div>
      <div className="gpt3__navbar-menu" ref={menuRef}>
        {toggleMenu
          ? <RiCloseLine color="#762a2a" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#762a2a" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><Link to="/home" className={isActive('/home')}>Home</Link></p>
            <p><Link to="/timechart" className={isActive('/timechart')}>Timeline</Link></p>
            <p><Link to="/methodology" className={isActive('/methodology')}>Methodology</Link></p>
            <p><Link to="/media" className={isActive('/media')}>Media</Link></p>
            <p><Link to="/people" className={isActive('/people')}>Team</Link></p>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
