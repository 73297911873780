import React, { useEffect, useState } from 'react';
import publicationsData from '../../jsons/mediaData.json'; // Adjust the path as needed
import './Media.css';

const Media = () => {
  const [publications, setPublications] = useState([]);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setPublications(publicationsData);
    setAnimate(true);
  }, []);

  const getImagePath = (path) => {
    try {
      return require(`../../assets/${path}`);
    } catch (err) {
      console.error(`Error loading image: ${path}`, err);
      return 'path/to/placeholder/image.jpg'; // Replace with the actual placeholder image path
    }
  };

  return (
    <div className={`media-container ${animate ? 'animate' : ''}`}>
      <h1 className="media-header">Media Coverage Highlights</h1>
      <section className="recent-publications">
        <h2 className="section-title">Latest Highlights</h2>
        <div className="media-grid">
          {publications.slice(1, 5).map((pub, index) => (
            <div className={`media-item ${animate ? `animate-${index}` : ''}`} key={index}>
              <img src={getImagePath(pub.image)} alt={pub.title} />
              {pub.url === ""? (
                  <span>{pub.title}</span>
                ) : (
                  <a href={pub.url} target="_blank" rel="noopener noreferrer">
                    {pub.title}
                  </a>
                )}
            </div>
          ))}
        </div>
      </section>
      <section className="publication-timeline">
        <h2 className="section-title">Publication History</h2>
        <div className="year">
        2024
        </div>
        
        <div className="timeline">
          {publications.map((pub, index) => (
            <div
              className="timeline-item"
              style={{ animationDelay: `${index * 0.3}s` }} // Add delay for each item
              key={index}
            >
              <div className="timeline-year">{pub.month}</div>
              <div className="timeline-content">
                {pub.url === ""? (
                  <span>{pub.title}</span>
                ) : (
                  <a href={pub.url} target="_blank" rel="noopener noreferrer">
                    {pub.title}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Media;
