import React, { useState, useEffect, useRef } from 'react';
import USAMap from 'react-usa-map';
import biasedData from '../../jsons/biasedPolls.json';
import correctedData from '../../jsons/correctedPolls.json';
import stateConversion from '../../jsons/stateAbbreviations.json';
import './Maps.css';
import stateNames from '../../jsons/stateNames.json';
import { FaInfoCircle } from 'react-icons/fa';

const Maps = () => {

    const [showHarris, setShowHarris] = useState(true);
    const [showDebiased, setShowDebiased] = useState(true);
    const [data, setData] = useState(correctedData);
    const [stateAbbreviations, setStateAbbreviations] = useState({});
    const [selectedOption, setSelectedOption] = useState(true); 
    const [hoveredState, setHoveredState] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [selectedCandidate, setSelectedCandidate] = useState('harris');
    const tooltipRef = useRef(null);
    const mapRef = useRef(null);


    useEffect(() => {
        const abbreviations = {};
        for (const state in stateConversion) {
          abbreviations[state] = stateConversion[state];
        }
        setStateAbbreviations(abbreviations);
    }, []);

    const handleToggleHarrisChange = () => {
        setShowHarris(!showHarris);
        const newSelectedCandidate = selectedCandidate === 'harris' ? 'biden' : 'harris';
        setSelectedCandidate(newSelectedCandidate);
    };

    useEffect(() => {
        const states = mapRef.current?.querySelectorAll("[data-name]");
        states?.forEach((state) => {
          state.addEventListener("mouseenter", handleMouseEnter);
          state.addEventListener("mouseleave", handleMouseLeave);
          state.addEventListener("mousemove", handleMouseMove);
        });
        return () => {
          states?.forEach((state) => {
            state.removeEventListener("mouseenter", handleMouseEnter);
            state.removeEventListener("mouseleave", handleMouseLeave);
            state.removeEventListener("mousemove", handleMouseMove);
          });
        };
    }, [mapRef, data]);

    const handleMouseEnter = (event) => {
        const stateName = event.target.getAttribute("data-name");
        if (stateName) {
          setHoveredState({ name: stateName });
          setShowTooltip(true);
        }
      };
    
    const handleMouseLeave = () => {
        setHoveredState(null);
        setShowTooltip(false);
    };
    
    const handleMouseMove = (event) => {
        setTooltipPosition({ x: event.clientX + 30, y: event.clientY - 30 });
    };


    const handleToggleChange = () => {
        const newShowDebiased = !showDebiased;
        setShowDebiased(newShowDebiased);
        const newOption = data === biasedData ? correctedData : biasedData;
        setData(newOption);
    };

    const calculateTotalPercentages = () => {
        let totalDemocratic = 0;
        let totalRepublican = 0;
        let maxDemocratic = 0;
        let maxRepublic = 0;
        let minDemocratic = 100;
        let minRepublic = 100;
    
        Object.values(data).forEach((data) => {
          const demRescaled = selectedCandidate === 'biden' ? data.avg_perc_biden_rescaled : data.avg_perc_harris_rescaled;
          const repRescaled = data.avg_perc_trump_rescaled_against_biden;
          maxRepublic = Math.max(maxRepublic, repRescaled);
          maxDemocratic = Math.max(maxDemocratic, demRescaled);
          minRepublic = Math.min(minRepublic, repRescaled);
          minDemocratic = Math.min(minDemocratic, demRescaled)

          if (demRescaled > repRescaled) {
            totalRepublican += data.num_polls_in_last_date_biden;
          } else {
            totalDemocratic += data.num_polls_in_last_date_biden;
          }
        });
    
        return { totalDemocratic, totalRepublican, maxDemocratic, maxRepublic, minDemocratic, minRepublic};
      };
    
      const {
        totalDemocratic,
        totalRepublican,
        maxDemocratic,
        maxRepublic,
        minDemocratic,
        minRepublic
      } = calculateTotalPercentages();

      const diffDemocratic = maxDemocratic - minDemocratic;
      const diffRepublic = maxRepublic - minRepublic;

      console.log(diffDemocratic);
      console.log(diffRepublic);

      const maxBlueIntensity = 255 - Math.round((maxDemocratic / 100) * 255);
      const minBlueIntensity = 255 - Math.round((minDemocratic/100)*255);
      const maxRedIntensity = 255 - Math.round((maxRepublic / 100) * 255);


      console.log(maxBlueIntensity);
      console.log(minBlueIntensity);
      console.log(maxRedIntensity);

      
    
      const totalVotes = totalDemocratic + totalRepublican;
    
      const democraticPercentage = (totalDemocratic / totalVotes) * 100;
      const republicanPercentage = (totalRepublican / totalVotes) * 100;

      const finalBlue = Math.round(255 * (maxRepublic / 100));
      const finalRed = Math.round(255 * (maxDemocratic / 100));
      const blueColorDark = `rgb(0, 0, ${finalBlue})`;
      const blueColorLight = `rgb(0, 0, ${finalBlue + 100})`;
      const redColorDark = `rgb(${finalRed}, 0, 0)`;
      const redColorLight = `rgb(${finalRed + 100}, 0, 0)`;


      const gradientStyle = {
        background : `linear-gradient(to right, ${blueColorDark}, ${blueColorLight} ${republicanPercentage}%, ${redColorLight} ${republicanPercentage}%, ${redColorDark})`
    };
    
      const winner = democraticPercentage > republicanPercentage ? "Democratic" : "Republican";
    
      

      console.log(blueColorDark);
      console.log(blueColorLight);
      console.log(redColorDark);
      console.log(redColorLight);
      console.log(winner);
      console.log(selectedOption);
      console.log(setSelectedOption);
      console.log(showTooltip);

    
      const calculateFillColor = (percentage, color) => {
        // Calculate intensity based on the percentage with reduced contrast
        const intensityRange = color === "red" ? diffRepublic : diffDemocratic;
        const minIntensity = color === "red" ? minRepublic : minDemocratic;
        const normalizedPercentage = (percentage - minIntensity) / intensityRange;
    
        // Use a square root to smooth out the contrast
        const intensity = Math.round(255 * (1 - Math.sqrt(normalizedPercentage * 0.25)));
    
        // Ensure intensity is within valid range [0, 255]
        const clampedIntensity = Math.max(0, Math.min(255, intensity));
    
        // Determine final color based on intensity
        const final_color = color === "red"
            ? `rgb(${clampedIntensity}, 0, 0)` // Corrected with backticks
            : `rgb(0, 0, ${clampedIntensity})`; // Corrected with backticks
    
        return final_color;
    };
        
      const statesCustomConfig = () => {
        const customConfig = {};
        for (let state in data) {
          const stateAbbreviation = stateAbbreviations[state];
          const trump_percentage = selectedCandidate === 'biden' ? data[state].avg_perc_trump_rescaled_against_biden : data[state].avg_perc_trump_rescaled_against_harris;
          const biden_percentage = selectedCandidate === 'biden' ? data[state].avg_perc_biden_rescaled : data[state].avg_perc_harris_rescaled;
          const percentage = trump_percentage > biden_percentage ? trump_percentage : biden_percentage;
          const color = trump_percentage > biden_percentage ? "red" : "blue";
          const fillColor = calculateFillColor(percentage, color);
          customConfig[stateAbbreviation] = {
            fill: fillColor,
            "data-name": state,
          };
        }
        return customConfig;
      };

    return(
        <div className="map-container">
            <h1 className='map-header'>Map</h1>
            <div className='map-description-container'>
              <p className='map-description'>View state-wise results of election polls on X and our estimates of public opinion per state based on debiased X polls.</p>
            </div>

            <div className='checkbox-container'>
                <div className='toggleButton'>
                    <label className='switch'>
                    <input
                        type='checkbox'
                        checked={showHarris}
                        onChange={handleToggleHarrisChange}
                    />
                    <span className='slider'></span>
                    </label>
                    <span className='toggleLabel'>Harris instead of Biden</span>
                </div>
                <div className='toggleButton'>
                    <label className='switch'>
                    <input
                    type='checkbox'
                    checked={showDebiased}
                    onChange={handleToggleChange}
                    />
                    <span className='slider'></span>
                    </label>
                    <span className='toggleLabel'>Show Debiased</span>
                    <div className='info-icon-container'>
                    <FaInfoCircle className='info-icon' />
                        <div className='info-tooltip'>The results of social polls post-stratified on demographics and partisanship of poll retweeters.</div>
                    </div>
                </div>
                <div className="gradient-bar-container">
                    <div className="gradient-bar">
                    <div class="tick-mark"></div>
                        <div className="gradient democratic" style={gradientStyle}></div>
                    </div>
                    <div className="gradient-labels">
                        <span>Maximum Democratic Votes</span>
                        <span>Maximum Republican Votes</span>
                    </div>
                </div>
                <div ref={mapRef}>
                    <USAMap customize={statesCustomConfig()} />
                </div>
                {hoveredState && data[stateNames[hoveredState.name]] && (
                    <div
                    className="tooltip"
                    ref={tooltipRef}
                    style={{
                        top: `${tooltipPosition.y}px`,
                        left: `${tooltipPosition.x}px`,
                    }}
                    >
                    <div className="tooltip-header">
                        <p><strong>State: </strong>{stateNames[hoveredState.name]}</p>
                    </div>
                    <div className="tooltip-body">
                      {selectedCandidate === 'biden' ?
                          <div>
                          <p><strong>Winner:</strong> {data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_biden > data[stateNames[hoveredState.name]].avg_perc_biden_rescaled ? "Republican": "Democratic"}</p>
                          <p><strong>Percentage:</strong> {data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_biden > data[stateNames[hoveredState.name]].avg_perc_biden_rescaled ? data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_biden: data[stateNames[hoveredState.name]].avg_perc_biden_rescaled}%</p>
                          </div>
                          :
                          <div>
                          <p><strong>Winner:</strong> {data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_harris > data[stateNames[hoveredState.name]].avg_perc_harris_rescaled ? "Republican": "Democratic"}</p>
                          <p><strong>Percentage:</strong> {data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_harris > data[stateNames[hoveredState.name]].avg_perc_harris_rescaled ? data[stateNames[hoveredState.name]].avg_perc_trump_rescaled_against_harris: data[stateNames[hoveredState.name]].avg_perc_harris_rescaled}%</p>
                          </div>
                      }
                        
                    </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default Maps;
